body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS HEX */
--yale-blue: #084b83ff;
--maximum-blue: #42bfddff;
--powder-blue: #bbe6e4ff;
--cultured: #f0f6f6ff;
--orange-yellow-crayola: #e9c46aff;

/* CSS HSL */
--yale-blue: hsla(207, 88%, 27%, 1);
--maximum-blue: hsla(192, 70%, 56%, 1);
--powder-blue: hsla(177, 46%, 82%, 1);
--cultured: hsla(180, 25%, 95%, 1);
--orange-yellow-crayola: hsla(43, 74%, 66%, 1);

/* SCSS HEX */
$yale-blue: #084b83ff;
$maximum-blue: #42bfddff;
$powder-blue: #bbe6e4ff;
$cultured: #f0f6f6ff;
$orange-yellow-crayola: #e9c46aff;

/* SCSS HSL */
$yale-blue: hsla(207, 88%, 27%, 1);
$maximum-blue: hsla(192, 70%, 56%, 1);
$powder-blue: hsla(177, 46%, 82%, 1);
$cultured: hsla(180, 25%, 95%, 1);
$orange-yellow-crayola: hsla(43, 74%, 66%, 1);

/* SCSS RGB */
$yale-blue: rgba(8, 75, 131, 1);
$maximum-blue: rgba(66, 191, 221, 1);
$powder-blue: rgba(187, 230, 228, 1);
$cultured: rgba(240, 246, 246, 1);
$orange-yellow-crayola: rgba(233, 196, 106, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #084b83ff, #42bfddff, #bbe6e4ff, #f0f6f6ff, #e9c46aff);
$gradient-right: linear-gradient(90deg, #084b83ff, #42bfddff, #bbe6e4ff, #f0f6f6ff, #e9c46aff);
$gradient-bottom: linear-gradient(180deg, #084b83ff, #42bfddff, #bbe6e4ff, #f0f6f6ff, #e9c46aff);
$gradient-left: linear-gradient(270deg, #084b83ff, #42bfddff, #bbe6e4ff, #f0f6f6ff, #e9c46aff);
$gradient-top-right: linear-gradient(45deg, #084b83ff, #42bfddff, #bbe6e4ff, #f0f6f6ff, #e9c46aff);
$gradient-bottom-right: linear-gradient(135deg, #084b83ff, #42bfddff, #bbe6e4ff, #f0f6f6ff, #e9c46aff);
$gradient-top-left: linear-gradient(225deg, #084b83ff, #42bfddff, #bbe6e4ff, #f0f6f6ff, #e9c46aff);
$gradient-bottom-left: linear-gradient(315deg, #084b83ff, #42bfddff, #bbe6e4ff, #f0f6f6ff, #e9c46aff);
$gradient-radial: radial-gradient(#084b83ff, #42bfddff, #bbe6e4ff, #f0f6f6ff, #e9c46aff);